//import axios from 'axios'
export default {
    namespaced: true,
    state: {
        welcomeMessage: true,
        menuOptions: {},

    },

    getters: {
        getWelcomeMessage (state) {
            return state.welcomeMessage
        },
        
        getMenuOptions (state) {
            return state.menuOptions
        }
    },

    mutations: {
        setWelcomeMessage (state, value){
            state.welcomeMessage = value
        },

        setMenuOptions (state, value) {
            state.menuOptions = value
        },        
    },
    
}    