<template>
    <v-card
        ref="form"
        style="overflow:hidden"
    >
        <v-card-title style="position:relative">
            <span class="headline">{{ formTitle }}</span>
            <v-btn
                fab
                text
                small
                absolute
                right
                top
                class="mr-12"
                @click="registrationForm"
            >
                <v-icon>mdi-printer</v-icon>
            </v-btn>
            <v-btn
                color="red"
                absolute
                top
                right
                text
                fab
                small
                @click="close"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text class="pb-0">
            <v-container>
                <v-row>
                    <v-col cols="3">
                        <v-row>
                            <v-col cols="12">
                                <v-card
                                    outlined
                                    class="mx-auto"
                                    width="200"
                                    height="230"
                                >
                                    <v-img
                                        v-if="studentRecord.no_picture"
                                        :src="studentRecord.picture"
                                        height="228"
                                        contain
                                    >
                                    </v-img>

                                    <v-img
                                        v-else
                                        :src="studentRecord.picture"
                                        height="228"
                                        cover
                                    >
                                    </v-img>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <v-card
                                    class="mx-auto"
                                    width="200"
                                    flat
                                >
                                    <v-text-field
                                        v-model="studentRecord.student_id"
                                        label="Student ID"
                                        readonly
                                        outlined
                                        filled
                                        dense
                                        class="mt-4 record-summary"
                                    ></v-text-field>
                                </v-card>
                            </v-col>
                        </v-row>

                    </v-col>

                    <v-col cols="9">
                        <v-card

                            flat
                            tile
                            style="border-left: thin solid rgba(0, 0, 0, 0.12)"
                        >
                            <v-tabs
                                v-model="tab"
                                fixed-tabs
                            >
                                <v-tab
                                    v-for="item in tabItems"
                                    :key="item"
                                >
                                    {{ item }}
                                </v-tab>
                            </v-tabs>

                            <v-tabs-items v-model="tab">
                                <data-general
                                    v-bind:formClasses="formClasses"
                                    v-on:update-students="updateStudents"
                                    v-bind:validate="validate"
                                    v-on:enable-tabs="enableTabs"
                                    v-on:save-status="saveStatus"
                                    ref="dataGeneral"
                                ></data-general>
                                <data-personal
                                    v-bind:record="dataPersonal"
                                    v-bind:ethnicGroups="ethnicGroups"
                                    v-bind:religiousGroups="religiousGroups"
                                ></data-personal>
                                <data-medical
                                    v-bind:record="dataMedical"
                                ></data-medical>
                                <data-family
                                    v-bind:records="dataFamily"
                                ></data-family>
                            </v-tabs-items>

                            <v-card-actions class="px-12">
                                <v-spacer></v-spacer>
                                 <v-btn
                                    class="mr-4"
                                    color="red"
                                    text
                                    @click="close"
                                >
                                    Close Record
                                </v-btn>
                                <v-btn
                                    outlined
                                    text
                                    @click="save"
                                >
                                    <v-icon left>
                                        mdi-content-save
                                    </v-icon>
                                    Save Record
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>

                </v-row>
            </v-container>
        </v-card-text>

        <v-overlay
            :value="overlay"
            opacity="0.8"
            z-index="10"
            absolute
        >

            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>

        <v-dialog
            v-model="reportViewer"
            max-width="90vw"
            @click:outside="closeReport"
        >
            <v-card
               height="90vh"
               style="position:relative;"
            >
                <iframe
                    ref="pdf"
                    style="width:100%; height:100%"
                    class="report-viewer"
                    :src="src"
                ></iframe>
                <v-overlay
                    absolute
                    :value="reportViewerOverlay"
                    color="white"
                    opacity="0.60"
                >
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackBar.display"
            :timeout="3000"
            :color="snackBar.color"
        >
            {{ snackBar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    text
                    @click="snackBar.display = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DataGeneral from './StudentsEditDataGeneral.vue';
import DataPersonal from './StudentsEditDataPersonal.vue';
import DataMedical from './studentsEditDataMedical.vue';
import DataFamily from './studentsEditDataFamily.vue';

export default {
    components: {
        DataGeneral,
        DataPersonal,
        DataMedical,
        DataFamily,
    },

    props:{
        formTitle: {
            type: String,
            default: '',
        },

        formClasses: {
            type: Array,
            default: function () {
                return []
            }
        },

        dialog: {
            type: Boolean,
            default: false
        }
    },

    created: function () {
        this.initialize();
    },

    computed: {
        ...mapGetters({
            studentRecord: 'students/getEditedStudent'
        })
    },

    data: () => ({
        tab: null,
        tabItems: ['General Info', 'Personal Info',  'Medical Info', 'Family Info'],
        errorMessage: '',
        saved: false,
        error: false,
        overlay: false,
        dataPersonal: {},
        dataMedical: {},
        dataFamily: {},
        religiousGroups: [],
        ethnicGroups: [],
        reportViewer: false,
        reportViewerOverlay: false,
        src: '',
        picture: false,
        pictureSrc: '',
        validate: false,
        snackBar: {
            text: "",
            color: "primary",
            display: false,
        }
    }),

    watch: {
        studentRecord: {
            deep: true,
            handler () {
                // console.log('edited student changed');
                // this.getData();
            }
        },

        dialog: {
            handler (val){
                if(val) this.initialize();
            }
        }

    },

    methods: {
        ...mapActions({
            getDataPersonal: 'students/getDataPersonal',
            getDataMedical: 'students/getDataMedical',
            getDataFamily: 'students/getDataFamily',
            getReligiousGroups: 'students/getReligiousGroups',
            getEthnicGroups: 'students/getEthnicGroups',
        }),

        async initialize () {
            if(this.studentRecord.student_id)
            this.getData();
        },

        enableTabs () {
            this.tabItems.forEach(item => {
                item.disabled = false;
            })
            this.overlay = false;
        },

        async getData () {
            const promiseDataPersonal = this.getDataPersonal();
            const promiseDataMedical = this.getDataMedical();
            const promiseDataFamily = this.getDataFamily();
            const promiseReligiousGroups = this.getReligiousGroups();
            const promiseEthnicGroups = this.getEthnicGroups();

            try {
                const [
                    { data: dataPersonal },
                    { data: dataMedical },
                    { data: dataFamily },
                    { data: religiousGroups },
                    {data: ethnicGroups}
                ] = await Promise.all([
                    promiseDataPersonal,
                    promiseDataMedical,
                    promiseDataFamily,
                    promiseReligiousGroups,
                    promiseEthnicGroups
                ])

                this.dataPersonal = dataPersonal;
                this.loadPicture(dataPersonal);
                this.dataMedical = dataMedical;
                this.dataFamily = Array.isArray(dataFamily) ? {} : dataFamily;

                this.religiousGroups = religiousGroups.map(group => {
                    return {
                        id: group.id,
                        title: group.grouping
                    }
                })

                this.ethnicGroups = ethnicGroups.map(group => {
                    return {
                        id: group.id,
                        title: group.grouping
                    }
                })


            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }



        },

        close () {
            this.$emit("close");
            this.tab = null;
            this.error = false;
            this.saved = false;
            this.date = null;
            // Object.keys(this.studentRecord).forEach(f => {
            //     if(f === 'first_name' || f === 'last_name' || f === 'form_class_id'){
            //         this.$refs[f].reset()
            //     }
            // })
        },

        updateStudents () {
            this.$emit('update-students');
        },

        registrationForm () {
            this.reportViewer = true;
            this.reportViewerOverlay = true;
            // console.log(this.studentRecord);
            this.$nextTick( function () {
                //console.log(this.$refs.pdf);
                this.$refs.pdf.onload = () => {
                    console.log('report is loaded');
                    this.reportViewerOverlay = false;
                }
            })
            this.src = process.env.VUE_APP_API_URI + "/api/registration-form/"
            + this.studentRecord.student_id;
        },

        closeReport () {
            this.src = null;
        },

        loadPicture (data) {
            // console.log(data);
            if(data.picture){
                this.picture = true;
                this.pictureSrc = data.picture;
            }
        },

        save () {
            // console.log(this.studentRecord);
            this.overlay = true;
            this.validate = true;
        },

        saveStatus ({ type, text}) {
            this.validate = false;
            this.overlay = false;
            if(type == 'success'){
                this.snackBar.text = text;
                this.snackBar.color = "primary";
                this.snackBar.display = true;
                return;
            }

            if(type == 'error'){
                this.snackBar.text = text;
                this.snackBar.color = "red";
                this.snackBar.display = true;
                return;
            }
        },
    }
}
</script>

<style scoped>
    ::v-deep .v-tab{
        max-width: 120px;
    }

    ::v-deep .v-slide-group__prev{
        display: none;
    }

    ::v-deep .v-btn--fab.v-size--small.v-btn--absolute.v-btn--top{
        top: 4px;
        right: 4px;
    }

    ::v-deep .record-summary input{
        font-weight: bold;
    }
</style>