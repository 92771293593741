<template>
    <v-col
        class="pl-0 pt-0"        
    >
        <v-sheet
            width="500"
        >
            <v-row>
                <v-card
                    width="650"
                    height="350"
                    class="mx-3 mt-3 pa-1"
                    flat
                    outlined                                
                >
                    <v-data-table
                        :headers="headers"
                        :items="studentTermMarks"
                        dense                    
                        fixed-header
                        height="340"
                        disable-pagination
                        hide-default-footer  
                    >
                        <template v-slot:[`item.course_mark`]="{ item }">
                            <span style="font-size:0.8rem">{{ item.course_mark }}</span>
                        </template>

                        <template v-slot:[`item.exam_mark`]="{ item }">
                            <span style="font-size:0.8rem">{{ item.exam_mark }}</span>
                        </template>

                        <template v-slot:[`item.comment`]="{ item }">
                            <span style="font-size:0.8rem">{{ item.comment }}</span>
                        </template>

                    </v-data-table>
                </v-card>
            </v-row>

            <v-row
                class="mt-3"                                
                align="center"
            >
                <v-col class="pa-0">
                    <v-card
                        height="75"
                        width="500"
                        class="pa-3 ml-3"
                        flat                    
                    >
                        <v-form ref="form">
                            <v-textarea                                       
                                label="Form Teacher's Comments"                           
                                dense
                                rows="2"
                                :value="value"                        
                                counter                                            
                                class="text-caption"                                              
                                no-resize
                                maxlength="215"
                                v-model="studentRecord.teacher_comment"                            
                                @blur="saveTeacherComment"
                                @click="openBottomSheet('form-teacher')"
                                :disabled="disabled"
                                :filled="disabled"                     
                            >                        
                            </v-textarea>
                        </v-form>
                    </v-card>
                </v-col>                  
                                            
            </v-row>

           
        </v-sheet>            
    </v-col>    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {    
    data: () => ({
        headers: [           
            {text: 'Subject', align: 'left', value: 'abbr', width: '100'},
            {text: 'Course', align: 'center', value: 'course_mark', width: '50'},
            {text: 'Exam', align: 'center', value: 'exam_mark', width: '50'},
            {text: 'Conduct', align: 'center', value: 'conduct', width: '60'},           
            {text: 'Comment', align: 'left', value: 'comment', width: '200'}
        ],             
        studentAverage: '',        
        value: ' ',       
        isValid: true, 
        record: {},
        subjectRecord: [],
        disabled: true,
        deanAccess: false,      
                
    }),       
    watch: {
        studentRecord: {
            handler(){                              
                console.log('record changed');
                if(this.formClassAssignment.includes(this.studentRecord.form_class_id)){
                    console.log('Assigned Form Teacher');
                    this.disabled = false;
                }
                else{
                    console.log('Not the Form Teacher');
                    this.disabled = true;
                }

                if(this.formDeanAssignments.includes(this.studentRecord.form_class_id)){
                    console.log('Assigned Form Dean');
                    this.deanAccess = true;
                }
                else{
                    console.log('Not Assigned Form Dean');
                    this.deanAccess = false;
                }
            }
        },
        sheet: {
            handler (val) {
                //console.log(`Sheet: ${val}`);
                if(!val) this.updateRecord();
            }
        }        
    },
    computed: {
        ...mapGetters({
            primaryColor: 'app/getPrimaryColor',
            colorDarken: 'app/getColorDarken',            
            studentRecord: 'termReports/getStudentRecord',
            studentTermMarks: 'termReports/getStudentTermMarks',
            formClassAssignment: 'termReports/getFormClassAssignment',            
            formDeanAssignments: 'termReports/getFormDeanAssignments',
            sheet: 'termReports/getSheet',            
        }),
        themeColor(){
            return this.primaryColor + ' ' + this.colorDarken;
        }
    },
    methods: {
        ...mapMutations({            
            setSaveStatus: 'termReports/setSaveStatus',
            setSaving: 'termReports/setSaving',
            setSaveError: 'termReports/setError',
            setSaved: 'termReports/setSaved',
            setCommentsSheet: 'termReports/setSheet',
            setCommentsSheetTitle: 'termReports/setSheetTitle',
            setCommentsSheetModel: 'termReports/setSheetModel',
            setSavedToDb: 'termReports/setSavedToDb',
            setUpdating: 'termReports/setUpdating', 
        }),
        ...mapActions({
            getTermMarks: 'termReports/getTermMarks',
            postStudentDetails: 'termReports/postStudentTermDetails',
            // setErrorResponse: 'termReports/setErrorResponse', 
        }),
        getAverage(){
            let recordCount = 0;
            let examMarkTotal = 0;
            let average = 0;
            this.table2Records.forEach(record => {
                recordCount++;
                examMarkTotal += record.exam_mark
            })
            if(recordCount != 0){
                average = (examMarkTotal / recordCount);
                //this.studentAverage = average + ' %';
                this.studentAverage = average.toPrecision(3) + ' %';
            }
            else{
                this.studentAverage = '-'
            } 
        },
        saveTeacherComment(){
            if(this.studentRecord.teacher_comment) this.updateRecord();            
        },
        saveDeanComment(){
            if(this.studentRecord.dean_comment) this.updateRecord();
        },
        openBottomSheet(post){
            console.log(post);
            if(post == 'form-teacher'){
                this.setCommentsSheetTitle('Form Teacher Comments');
                this.setCommentsSheetModel('teacher_comment');
            } 
            else if (post == 'dean'){
                this.setCommentsSheetTitle('Dean Comments'); 
                this.setCommentsSheetModel('dean_comment')
            }            
            this.setCommentsSheet(true);
            //console.log(`sheet: ${ this.sheet }`);
        },
        async updateRecord(){
            console.log('updating...');
            this.setUpdating(true);
            this.setSaveStatus('Saving...');
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);
            this.setSavedToDb(false);
            this.setUpdating(false);
            console.log(this.studentRecord);
            try{
                let response = await this.postStudentDetails();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
                console.log(response.status);
            } catch (error) {
                console.log(error);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);
                this.setErrorResponse(error);
            }
            
        },           
    }
}
</script>

<style scoped>
    ::v-deep .v-text-field__slot textarea{
        line-height: 1rem;
        font-size: 13px;
    }

    ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        padding: 0 6px;
    }

    ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        padding: 0 6px;
    }
</style>