<template>
    <v-col>
        <v-sheet
            width="250"
        >
           <v-list
               
                
            >
               <template v-for="(item, index) in behaviourMatrix">
                    <v-list-item
                    :key="index"
                    >
                        <v-list-item-content
                            
                        >
                            <v-card
                                flat
                                class="text-caption" 
                                v-text="item.title"
                                width="150"
                            ></v-card>                              
                        </v-list-item-content>
                        <v-card
                            flat
                            width="70"
                        >
                            <v-select
                                :items="ratings"
                                item-value="abbr"                                
                                dense
                                hide-details
                                full-width
                                outlined
                                :disabled="item.disabled"
                                :filled="item.disabled"
                                v-model="studentRecord[item.model]"
                                @input="updateRecord"                    
                            >
                                <template v-slot:selection="{item}">
                                    {{item.abbr}}
                                </template>
                                <template v-slot:item=data>
                                    {{ data.item.abbr }} - {{ data.item.text }}
                                </template>
                            </v-select>
                        </v-card>  
                   </v-list-item>
               </template>               
           </v-list>
        </v-sheet>
    </v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    data: () => ({
        ratings: [
            {text: 'Most of the time', abbr: 'M'},
            {text: 'Some of the time', abbr: 'S'},
            {text: 'Hardly Ever', abbr: 'H'},            
        ],
        behaviourMatrix: [
            {title: 'Wears the Uniform Correctly', model: 'uniform', disabled: true},
            {title: 'Willingly assists/obeys those in authority', model: 'authority', disabled: true},
            {title: 'Shows respect/concern for others and school property', model: 'respect', disabled: true},
            {title: 'Remains in class as instructed', model: 'instruction', disabled: true},
            {title: 'Participates in class', model: 'participation', disabled: true},
            {title: 'Follows school\'s rules', model: 'rules', disabled: true},
            {title: 'Participates in co/extra-curricular school activities', model: 'co_curricular', disabled: true},
        ]
    }),
    computed: {
        ...mapGetters({
            studentRecord: 'termReports/getStudentRecord',
            formClassAssignment: 'termReports/getFormClassAssignment', 
        })
    },
    watch: {
        studentRecord: {
            handler () {
                console.log(this.studentRecord);
                if(this.formClassAssignment.includes(this.studentRecord.form_class_id)){
                    console.log('Assigned Form Teacher');
                    this.behaviourMatrix.forEach(element => {
                        element.disabled = false;
                    })                    
                }
                else{
                    console.log('Not the form Teacher');
                    this.behaviourMatrix.forEach(element => {
                        element.disabled = true;
                    })
                }
            }
        }
    },
    methods: {
         ...mapActions({
            getStudentTermDetails: 'termReports/getStudentTermDetails',            
            postStudentDetails: 'termReports/postStudentTermDetails',        
        }),
        ...mapMutations({
            setSaveStatus: 'termReports/setSaveStatus',
            setSaving: 'termReports/setSaving',
            setSaved: 'termReports/setSaved',
            setSaveError: 'termReports/setError',
            setSavedToDb: 'termReports/setSavedToDb',
            setUpdating: 'termReports/setUpdating', 
        }),
        async updateRecord(){
            console.log('updating...');
            this.setUpdating(true);
            this.setSavedToDb(false);
            this.setSaveStatus('Saving...');
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);            
            try{
                let response = await this.postStudentDetails();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
                this.setUpdating(false);
                console.log(response);
            } catch (error) {
                console.log(error.response);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);                
            }        
        }, 
    }
}
</script>