<template>
    <v-app>
        <v-app-bar 
            app
            color="secondary"
            dark
            clipped-left
            elevation="0"
        > 
            <div class="d-flex align-center text-uppercase font-weight-bold primary--text">
                <v-img
                    alt="Vuetify Logo"
                    class="shrink mr-4"
                    contain
                    src="../assets/logo.png"
                    transition="scale-transition"
                    width="50"
                />
                {{ schoolName }} School
                
            </div>
            <v-spacer></v-spacer>
            <v-avatar
                color="primary"
                class="mr-6"
                size="36"                                
            >
                <span class="secondary--text button">{{ initials }}</span>
            </v-avatar>
            <Logout/>
        </v-app-bar>

        <v-main class="grey lighten-5">
            <v-container
                fluid                
            >
                <v-row v-if="welcomeMessage" class="mt-10">
                    <v-card 
                        class="grey lighten-5 "                        
                        flat
                        width="460"
                        height="40"
                    >
                        
                        <div class="d-flex justify-center text-body-1 primary--text">
                            <span class="mr-3 d-flex align-end">Welcome</span> 
                            <span class="font-weight-light font-italic text-h5 d-flex align-end">{{ user }}</span>
                        </div>                       
                        <div class="caption" v-if="lastLogin">Last login: </div>                        
                        
                    </v-card>
                    <v-spacer></v-spacer>
                    <span
                        class="d-flex mr-10 justify-center text-body-1 primary--text"
                    >{{ currentAcademicPeriod }}</span>
                </v-row>
                <v-row>
                    <v-col
                        cols="1"
                    >
                        <v-card
                            width="70"
                            flat
                            class="d-flex flex-column align-center grey lighten-5"
                            v-if="!menuOptions.mainMenu"
                        >
                            <v-expand-transition>
                                <v-btn
                                    color="primary"
                                    dark
                                    fab
                                    small
                                    @click="showMainMenu"    
                                >
                                    <v-icon>
                                        mdi-arrow-left
                                    </v-icon>
                                    
                                </v-btn>
                            </v-expand-transition>
                            <div
                                class="caption mt-2"
                            >
                                Main Menu
                            </div>
                        </v-card>
                    </v-col>
                    <v-col
                        cols="11"
                    >
                        <main-menu v-if="menuOptions.mainMenu"></main-menu>
                        <mark-sheet v-if="menuOptions.markSheet"></mark-sheet>
                        <edit-view-term-reports v-if="menuOptions.termDetails"></edit-view-term-reports>
                        <print-view-reports v-if="menuOptions.reports"></print-view-reports>
                        <employees v-if="menuOptions.employees"></employees>
                        <student-subjects v-if="menuOptions.subjects"></student-subjects>
                        <students v-if="menuOptions.students"></students> 
                        <reset-password v-if="menuOptions.resetPassword"></reset-password>                       
                        <settings v-if="menuOptions.settings"></settings>
                    </v-col>
                    
                </v-row>                    
                              
            </v-container> 
        </v-main>        
        <sweet-modal
            :icon="modalIcon"
            ref="modal"
            overlay-theme="dark"
            :title="modalTitle"
            v-on:close="closeModal"
        >
            {{ modalMessage }}
        </sweet-modal>
        <v-overlay
            :value="appOverlay"
            opacity="0.8"
            z-index="10"                    
        >
            
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>                   
        </v-overlay>
    </v-app>
</template>

<script>
import Logout from './auth/Logout';
import MainMenu from './MainMenu';
import MarkSheet from './MarkSheet';
import EditViewTermReports from './EditViewTermReports';
import PrintViewReports from './PrintViewReports';
import Employees from './Employees';
import StudentSubjects from './StudentSubjects';
import Students from './Students.vue';
import ResetPassword from './ResetPassword';
import Settings from './Settings';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { SweetModal } from 'sweet-modal-vue';


export default {
    name: 'Admin',
    components: {
        Logout,        
        MainMenu,
        MarkSheet,
        EditViewTermReports,
        PrintViewReports,
        Employees,
        StudentSubjects,
        Students,
        SweetModal,
        ResetPassword,
        Settings,        
    },
    created(){ 
        this.initialize();                
    },
    watch: {
        modal:{
            handler(value){
                console.log('opening modal..')
                console.log(this.modalIcon);
                if(value) this.openModal();
            }
        }
    },
    computed: { 
        ...mapGetters({
            getUser: 'auth/getUser',
            schoolName: 'app/getSchoolName',
            user: 'auth/getEmployeeSignedIn',
            initials: 'auth/getEmployeeInitials',
            welcomeMessage: 'mainMenu/getWelcomeMessage',
            modal: 'app/getModal',
            modalTitle: 'app/getModalTitle',
            modalIcon: 'app/getModalIcon',
            modalMessage: 'app/getModalMessage',           
            appOverlay: 'app/getAppOverlay',
            settings: 'mainMenu/getSettings',
            currentAcademicPeriod: 'app/getCurrentAcademicPeriod',
            menuOptions: 'mainMenu/getMenuOptions',            
        }),
            
    },
    
    data: () => ({                
        lastLogin: false,
        //currentAcademicPeriod: null,
        menuItems: {
            mainMenu: false,               
            markSheet: false,
            termDetails: false,
            reports: false,
            employees: false,
            subjects: false,
            students: false,
            // marksArchive: false,
            resetPassword: false,
            settings: false,
        }         
    }),
    
    methods: {
        ...mapMutations({
            setModal: 'app/setModal',
            setAppOverlay: 'app/setAppOverlay',
            setAcademicYearId: 'app/setAcademicYearId',
            setAcademicTermId: 'app/setAcademicTermId',
            setAcademicYear: 'app/setAcademicYear',
            setTerm: 'app/setTerm',
            setWelcomeMessage: 'mainMenu/setWelcomeMessage',
            setCurrentAcademicPeriod: 'app/setCurrentAcademicPeriod',
            setMenuOptions: 'mainMenu/setMenuOptions',
        }),

        ...mapActions({
            academicPeriod: 'app/getAcademicPeriod',
        }),

        async initialize(){
            this.setMenuOptions(this.menuItems);
            this.showMainMenu();
            try {
                let response = await this.academicPeriod(); 
                // console.log(response)               ;
                let academicYearId = response.data.academic_year_id;
                let academicTermId = response.data.id;
                let term = response.data.term; 
                
                this.setAcademicTermId(academicTermId);               
                this.setAcademicYearId(academicYearId);
                this.setTerm(term);                
                this.setCurrentPeriod(term, academicYearId);
                              
            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error);
            }           
            
            this.setAppOverlay(false);
        },

        setCurrentPeriod(term, academicYearId){
            let year = academicYearId.toString().substr(0, 4) + '-' + academicYearId.toString().substr(4);
            this.setAcademicYear(year);
            this.setCurrentAcademicPeriod(`Term: ${ term } Year: ${ year}`);  
        },

        openModal(){            
            this.$refs.modal.open();
        },
        
        closeModal(){
            this.setModal(false);
        },

        showMainMenu(){                        
            this.setWelcomeMessage(true);
            Object.keys(this.menuOptions).forEach(key => {
                this.menuOptions[key] = false;
                if(key == 'mainMenu') this.menuOptions[key] = true;
            })            
        },
             
    }
}
</script>