<template>
    <v-tab-item style="overflow-y:auto; height: 70vh">                                                
        <v-card
            width="400"
            flat
            class="mx-auto mt-3 px-4"
        >
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        ref="first_name"
                        v-model="record.first_name"
                        label="First Name"
                        hide-details="auto"
                        :rules="rules"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-text-field
                        ref="last_name"
                        v-model="record.last_name"
                        label="Last Name"
                        hide-details="auto"
                        :rules="rules"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">                                            
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"                   
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="record.date_of_birth"
                                label="Date of Birth (Optional)"                                                    
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                            >
                            </v-text-field>
                        </template>
                        <v-date-picker
                            ref="picker"
                            v-model="record.date_of_birth"                                                
                            min="1950-01-01"
                            @change="saveDate"                                                
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        ref="birth_certificate_pin"
                        v-model="record.birth_certificate_pin"
                        label="Birth Certificate Pin"
                        hide-details="auto"
                        :rules="rules"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-select
                        ref="gender"
                        v-model="record.gender"
                        :items="gender"
                        label="Gender (Optional)"
                        hide-details
                        :rules="rules"
                    >
                    </v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-autocomplete
                        ref="form_class_id"
                        v-model="record.form_class_id"
                        :items="formClasses"
                        item-text="id"
                        item-value="id"
                        label="Form Class"
                        hide-details="auto"
                        :rules="rules"                                            
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>
        </v-card>                                                
    </v-tab-item>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    created: function () {
        this.initialize();
    },
    
    props:{ 
        formClasses: {
            type: Array,
            default: function () {
                return []
            }
        },

        newStudent: {
            type: Boolean,
            default: false,
        },

        validate: {
            type: Boolean,
            default: false,
        }
    }, 

    watch: {       

        menu (val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },        

        studentRecord: {
            handler () {
                this.initialize();
            }
        },

        validate: {
            handler (val) {
                if(val) this.validateForm();
            }
        }
    },

    data: () => ({
        gender: ['M', 'F'],       
        rules: [
            value => !!value || 'Required'
        ],
        menu: false,
        record: {
            id: null,
            birth_certificate_pin: null,
            first_name: null,
            last_name: null,
            form_class_id: null,
            other_name: null,
            gender: null,
            date_of_birth: null,
        },
        errorMessage: null,
        successMessage: null,                    
    }),

    computed: {
        ...mapGetters({
            studentRecord: 'students/getEditedStudent',
        })
    },

    methods: {
        initialize () {
            this.record = { ...this.studentRecord };
        },

        ...mapActions({
            postStudent: 'students/postStudent',
            postClassRegistration: 'students/postClassRegistration',
        }),

        ...mapMutations({
            setEditedStudent: 'students/setEditedStudent',
            setClassRegistration: 'students/setClassRegistration',
        }),        

        saveDate(date){
            this.$refs.menu.save(date);
        },

        async update (record) {  
            try {
                if(!this.record.student_id){
                    console.log("new student");
                    const { data: { student } } = await this.postStudent();
                    console.log(student);
                    this.record.id = student.id;
                    console.log(this.record)
                    this.setEditedStudent({...student});
                    this.$emit('enable-tabs');
                    this.$emit('update-students');
                    this.$emit('save-status', {type:'success', text: 'Data successfully saved!'});
                    return;
                }
                
                await this.postStudent();

                if(this.record.form_class_id){
                    await this.postClassRegistration();
                } 

                this.setEditedStudent(record);

                this.$emit('save-status', {type:'success', text: 'Data successfully saved!'});

                this.$emit('update-students');
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }           
                     
        },

        validateForm () {
            let formHasErrors = false;
            let record = {...this.record}
            
            this.setEditedStudent({
                id: this.record.student_id,   
                ...this.record             
            });

            this.setClassRegistration({
                id: this.record.student_id,
                form_class_id: this.record.form_class_id
            })

            Object.keys(this.record).forEach( f => {
                if(
                    (
                        f === 'birth_certificate_pin' ||
                        f === 'first_name' ||
                        f === 'form_class_id' ||
                        f === 'last_name'
                    ) &&
                    !this.record[f]
                ){
                    formHasErrors = true;
                    this.$refs[f].validate(true);
                }                
            }) 
            
             if(formHasErrors){
                this.$emit('save-status', {type:'error', text: 'Please fill out the missing fields.'});
                this.setEditedStudent(record);
                return false;
            }

            this.update(record);
            
        },

    }
}
</script>