<template>
    <v-dialog
        v-model="dialog"
        max-width="90vw"
        persistent
    >
        <v-card height="90vh">
            <v-system-bar  window color="primary" dark>
                <v-spacer></v-spacer>
                <v-btn small icon @click="closeDialogMarkSheet">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-system-bar>
        </v-card>
    </v-dialog>
</template>

<script>
export default{
    props: {
        dialogOpen: Boolean,
    },
    data: () => ({
        dialog: this.dialogOpen,
    }),

    methods: {
        closeDialogMarkSheet () {
            console.log('close marksheet')
        }
    }
}
</script>